import { Badge } from "@/components/ui/badge";
// import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Area } from "@/interfaces/area.interface";
import useVerify from "@/hooks/use-verify";
import {
  // PiDotsThreeOutlineVerticalFill,
  PiPower,
} from "react-icons/pi";
import { inactiveArea, activeArea } from "@/services/areaService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "@/hooks/use-toast";
import Loader from "@/components/general-components/Loader";
import ConfirmDialog from "@/components/general-components/ConfirmDialog";
import { useState } from "react";
import AreaForm from "./AreaForm";
import { ApiError } from "@/interfaces/api.interface";
import { Separator } from "@/components/ui/separator";
import { SendHorizontal } from "lucide-react";
import { useNavigate } from "@tanstack/react-router";

interface AreaItemProps {
  area: Area;
}

const AreaItem = ({ area }: AreaItemProps) => {
  const { can } = useVerify();
  const queryClient = useQueryClient();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [actionType, setActionType] = useState<"activate" | "deactivate">();

   // Mutation para inativar o usuário
  const { mutate: deactivateArea, isPending: isInactivating } = useMutation({
    mutationFn: (areaId: number) => inactiveArea(areaId),
    onSuccess: () => {
      toast({
        title: "Área inativada!",
        description: `Área ${area.name} inativada com sucesso`,
        variant: "default",
        duration: 5000
      })
      queryClient.invalidateQueries({ queryKey: ['listAreasCompany'] });
    },
    onError: (error: ApiError) => {
      toast({
        title: "Erro ao inativar o área!",
        description: error.response?.data?.message || "Erro desconhecido.",
        variant: "destructive",
        duration: 5000
      })
    }
  });

  // Mutation para ativar o usuário
  const { mutate: activateArea, isPending: isActivating } = useMutation({
    mutationFn: (areaId: number) => activeArea(areaId),
    onSuccess: () => {
      toast({
        title: "Área Reativado!",
        description: `Área ${area.name} foi reativada com sucesso`,	
        variant: "success",
        duration: 5000
      })
      queryClient.invalidateQueries({ queryKey: ['listAreasCompany'] });
    },
    onError: (error: ApiError) => {
      toast({
        title: "Erro ao reativar o área!",
        description: error.response?.data?.message || "Erro desconhecido.",
        variant: "destructive",
        duration: 5000
      })
    }
  });

  const handleConfirmAction = () => {
    if (!area.id) return;

    if (actionType === "activate") {
      activateArea(area.id);
    } else {
      deactivateArea(area.id);
    }
    setIsDialogOpen(false);
  };

  const navigate = useNavigate();

  return (
    <>
      {/* Conteúdo do item */}
      <div className="relative shadow bg-background/50 rounded flex flex-col overflow-hidden border w-full">
        {/* Avatar e Nome */}
        <div className="relative min-h-24 h-44 w-full overflow-hidden">
          <img
            src={area.imageUrl || undefined}
            alt={area.name}
            className="h-full w-full object-cover rounded-t"
          />
        </div>

        <div className="flex flex-col p-2 gap-2 justify-between">
          <div className="flex justify-between items-baseline">

            {/* Status */}
            <div className="">
              <Badge
                variant="outline"
                className={`${
                  area.inactiveAt
                    ? "bg-red-500 text-red-100 dark:bg-red-700 dark:text-red-200"
                    : "bg-green-500 text-green-100 dark:bg-green-700 dark:text-green-200"
                } rounded-full px-2 py-1 text-xs`}
              >
                {area.inactiveAt ? "Área Inativa" : "Área Ativa"}
              </Badge>
            </div>

            {/* Data de Criação */}
            <div className="flex items-baseline gap-1 text-xs">
              <p className="text-xs font-medium text-gray-800 dark:text-gray-300">
                Cadastro:
              </p>
              <p className="text-xs text-gray-600 dark:text-gray-100 truncate">
                {new Date(area.createdAt || '2024-01-01').toLocaleDateString()}
              </p>
            </div>

          </div>
          
          <Separator />

          <div className="flex flex-col gap-1 h-10">
            <h2 className="text-sm font-semibold truncate">{area.name}</h2>
            <h2 className="text-xs truncate">{area.description}</h2>
          </div>

          
          <Separator />

          <Button
            variant="outline"
            onClick={() => {
              navigate({
                to: `/confinus/areas/${area.id}`,
              })
            }}
            className="flex items-center gap-1 cursor-pointer h-7 w-full text-xs truncate"
          >
            <p>Ir para Área</p>
            <SendHorizontal className="w-2.5 h-2.5 ml-1" />
          </Button>
          

          {/* Ações */}
          <div className="flex justify-between gap-2">
            {!area.inactiveAt ? (
              can("activate_area_confinus") && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    setActionType("deactivate");
                    setIsDialogOpen(true);
                  }}
                  className="flex items-center gap-1 cursor-pointer h-7 w-1/2 text-xs truncate"
                >
                  <PiPower />
                  <p>Inativar</p>
                </Button>
              )
            ) : (
              can("inactive_area_confinus") && (
                <Button
                  variant="ghost"
                  onClick={() => {
                    setActionType("activate");
                    setIsDialogOpen(true);
                  }}
                  className="flex items-center gap-1 cursor-pointer h-7 w-1/2 text-xs truncate"
                >
                  <PiPower />
                  <p>Reativar</p>
                </Button>
              )
            )}

            {can("update_area_confinus") && <AreaForm areaToEdit={area} />}
          </div>
        </div>
      </div>

      {isInactivating && <Loader title={"Inativando..."} />}
      {isActivating && <Loader title={"Ativando..."} />}

      {/* Diálogo de Confirmação */}
      <ConfirmDialog
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        variant={actionType === "activate" ? "default" : "destructive"}
        title={actionType === "activate" ? "Reativar Área" : "Inativar Área"}
        description={`Tem certeza que deseja ${actionType === "activate" ? "ativar" : "inativar"} esta Área?`}
        onConfirm={handleConfirmAction}
      />
    </>
  )
};

export default AreaItem;
