import UserItem from "./UserItem";
import { useQuery } from "@tanstack/react-query";
import useVerify from "@/hooks/use-verify";
import { listUsersCompany } from "@/services/userService";
import { User } from "@/interfaces/user.interface";
import UserForm from "./UserForm";
import UserItemSkeleton from "./Skeletons/userItemSkeleton";
import { useState } from "react";
import UserSearchForm from "./UserSeachForm";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import Pagination from "@/components/general-components/Pagination";

const Users = () => {
  const [searchParams, setSearchParams] = useState({
    limit: 10,
    pagination: 0,
  });

  const handleSearch = async (params: any) => {
    setSearchParams((prev) => ({
      ...prev,
      ...params,
    }));
    await listUsersCompany(params);
  };

  const handleClear = () => {
    setSearchParams({
      limit: 10,
      pagination: 0,
    });
  };
  
  const { can } = useVerify();

  const { data: usersData, isLoading, isError, error } = useQuery({
    queryKey: ['listCompanyUsers', searchParams],
    queryFn: () => listUsersCompany(searchParams),
  });

  const handleLimitChange = (name: string, value: string | number) => {
    setSearchParams((prev) => ({ ...prev, [name]: value }));
  };

  const handlePageChange = (page: number) => {
    setSearchParams((prev) => ({ ...prev, pagination: page }));
  };

  const totalPages = usersData ? Math.ceil(usersData.total / searchParams.limit) : 0;

  const skeletons = Array(3).fill(null);

  if (!can('view_user')) return null;

  return (
    <>
      <div className="flex flex-col md:flex-row mb-4 items-start justify-between md:items-center">
        <div>
          <h1 className="text-xl font-bold">Usuários do Sistema</h1>
          <span className="text-gray-600 dark:text-gray-100">Administrar Usuários</span>
        </div>
      </div>
      <div className="flex my-2 gap-2 items-start justify-between md:items-center">
        <div className="flex justify-start items-center gap-2">
          <div className="flex items-baseline gap-2">
            <Label htmlFor="limit">Itens</Label>
            <Select
              onValueChange={(value) => handleLimitChange("limit", Number(value))}
              value={searchParams.limit.toString()}
              
            >
              <SelectTrigger className="h-9">
                <SelectValue placeholder="" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="10">10</SelectItem>
                <SelectItem value="30">30</SelectItem>
                <SelectItem value="50">50</SelectItem>
                <SelectItem value="100">100</SelectItem>
                <SelectItem value={usersData?.total}>{usersData?.total}</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <UserSearchForm onSubmit={handleSearch} onClear={handleClear} />
        </div>
          {
            can('create_user') && (
              <UserForm />
            )
          }
      </div>
      <div className="space-y-2 mt-4">
        {isLoading
          ? skeletons.map((_, i) => <UserItemSkeleton key={i} index={i} />)
          : isError
          ? <p>Erro: {error?.message}</p>
          : usersData?.users && usersData.users.length > 0 
          ? usersData.users.map((user: User, i: number) => (
              <UserItem key={user.id} user={user} index={i} />
            ))
          : (
            <div className="w-full flex justify-center items-center py-4 rounded border border-primary">
              <p>Nenhum usuário encontrado!</p>
            </div>
          )
        }
      </div>
      <div className="mt-4">
        {totalPages >= 1 && (
            <Pagination
              totalItems={usersData?.total}
              itemsPerPage={searchParams.limit}
              currentPage={searchParams.pagination}
              onPageChange={handlePageChange}
              maxVisiblePages={5}
            />
          )}
      </div>
    </>
  );
};

export default Users;
